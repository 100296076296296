@import url(https://rsms.me/inter/inter.css);
html {
  background: #f6f6f6;
  line-height: 0.5;
  margin: 0 auto;
}

body {
  margin: 0;
  font-feature-settings: "kern";
  font-kerning: normal;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.85px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #00303b;
}

main {
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: clamp(200%,3vw,300%);
}

h2 {
  color: #999;
}

h1, h2, h3 {
  font-size: 200%;
  font-weight: 800;
  margin: 0 auto;
  line-height: 1.5;
}

p {
  line-height: 1.5;
}

p.link-paragraph {
  margin: 4px;
  padding: 8px;
  letter-spacing: -0.85px;
}

a {
  color: #00303b;
  font-size: 80%;
}

hr {
  width: 30%;
  border: 2px solid #777;
}

img {
  display: block;
  margin: 0 auto 2em;
}

input {
  width: 100%;
  margin-bottom: 8px;
  height: 24px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
}

.wrapper {
  width: 24%
}

.label {
  font-size: 100%;
  font-weight: bold;
  color: black;
  vertical-align: text-bottom;
}

.performance-list {
    max-width: 1000px;
    width: 90vw;
    margin: 0 auto;
    text-align: left;
}
.card {
    padding-bottom: 4px;
}

.card-body {
    color: #00303b;
}

.card-header {

}

.card-time {
    font-size: 150%;
}

.card-text {

}

button, input[type=submit] {
    background: transparent;
    border: 2px solid #cc0;
    color: #cc0;
    font-size: 90%;
    width: auto;
}

button:hover, input[type=submit]:hover {
    cursor: pointer;
}

.card-button {
    margin-bottom: 12px;
    margin-right: 8px;
}

.card-button-plus {
    width: 28px;
    height: 28px;
}

